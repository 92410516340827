<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div>句馆会员协议</div>
                </div>
            </div>

            <div class="admin_form_main">
                <el-form  label-width="100px" ref="info" :model="info">
                    <el-form-item label="内容" class="width_auto_70">
                        <wangeditor @goods_content="goods_content" :contents="info.jughyxy"></wangeditor>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('info')">提交</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    import wangeditor from "@/components/seller/wangeditor.vue"
    export default {
        components: {
            wangeditor,
        },
        props: {},
        data() {
            return {
                info:{
                    jughyxy:''
                },
            };
        },
        watch: {},
        computed: {},
        methods: {
            submitForm:function(e){
                this.$refs[e].validate(res=>{
                    if(res){
                        // Http 请求
                        this.$post(this.$api.huiyxyConfig,this.info).then(postRes=>{
                            if(postRes.code == 200){
                                this.$message.success("编辑成功");
                                this.get_web_config();
                            }else{
                                this.$message.error("编辑失败");
                            }
                        });
                    }
                });
            },
            handleAvatarSuccess(res) {
                this.info.guanggt = res.data;
                this.$forceUpdate();
            },
            get_web_config(){
                this.$get(this.$api.huiyxyConfig).then(res=>{
                    this.info = res.data;
                    console.log(666)
                    console.log(res.data)
                    console.log(666)
                });
            },
            // 富文本编辑内容变化
            goods_content:function(data){
                this.info.jughyxy = data;
            },

        },
        created() {
            this.get_web_config();
            this.upload_headers.Authorization = 'Bearer '+localStorage.getItem('token'); // 要保证取到
        },
        mounted() {}
    };
</script>
<style lang="scss" scoped>

</style>